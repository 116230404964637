import { Capacitor } from '@capacitor/core'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Provider as UrqlProvider, useSubscription } from 'urql'
import { notifsClient } from '../graphql/clients'
import {
    latestNotificationTimestamp,
    markEventsReadMutation,
    notificationSettingsMutation,
    notificationSettingsQuery,
    notificationsQuery,
} from '../graphql/queries-notifs/notifications'
import { useToastyMutation, useToastyQuery } from '../hooks/data'

import { clearBadgeCount, setBadgeCount } from '../app/badge'
import {
    EventGroup,
    EventGroupPage,
    NotificationSettings,
    SetNotificationSettingsInput,
} from '../graphql/notifs'
import { useFreshAccessToken } from '../hooks/token'

type NotificationFetchContextInterface = object

interface NotificationArgs {
    cursor?: string
    read?: boolean
    grouped?: boolean
}

interface NotificationContextInterface {
    markNotifications: (markIds: EventGroup['id'][]) => void
    saveNotificationSettings: (input: SetNotificationSettingsInput) => Promise<boolean>
    args: NotificationArgs
    setArgs: React.Dispatch<React.SetStateAction<NotificationArgs>>
}

interface NotificationStoreContextInterface {
    unreadCount: number
    notificationSettings?: NotificationSettings
    setNotificationSettings: React.Dispatch<React.SetStateAction<NotificationSettings | undefined>>
    notifications?: EventGroupPage
    setNotifications: (EventGroupPage) => void
}

export const NotificationFetchContext = React.createContext<NotificationFetchContextInterface>(
    {} as NotificationFetchContextInterface
)
export const NotificationStoreContext = React.createContext<NotificationStoreContextInterface>(
    {} as NotificationStoreContextInterface
)
export const NotificationContext = React.createContext<NotificationContextInterface>(
    {} as NotificationContextInterface
)
export default NotificationContext

// this provider is used to store notifcations in a context usable throughout the whole site
export const useNotificationStore = () => useContext(NotificationStoreContext)

export const NotificationStoreProvider = ({ children }) => {
    const [notificationSettings, setNotificationSettings] = useState<NotificationSettings>()
    const [notifications, setNotifications] = useState<EventGroupPage>()

    // count the unread notifications
    // difference between compact and grouped notifications
    const unreadCount = useMemo(() => {
        if (notificationSettings?.notificationFormat === 'COMPACT') {
            let count = 0
            notifications?.nodes
                .filter((n) => !n.read)
                .forEach((n) => {
                    count += n.events.length
                })
            return count
        }
        return notifications?.nodes.filter((n) => !n.read).length || 0
    }, [notifications, notificationSettings?.notificationFormat])

    const handleSetNotifications = useCallback((value: EventGroupPage) => {
        setNotifications(value)
    }, [])

    // Use the unread count to set the badge count
    useEffect(() => {
        console.log('Set badge count to', unreadCount)
        if (unreadCount === 0) {
            clearBadgeCount()
        } else {
            setBadgeCount(unreadCount)
        }
    }, [unreadCount])

    return (
        <NotificationStoreContext.Provider
            value={{
                unreadCount,
                notificationSettings,
                setNotificationSettings,
                notifications,
                setNotifications: handleSetNotifications,
            }}
        >
            {children}
        </NotificationStoreContext.Provider>
    )
}

interface NotificationFetchProviderProps {
    markIds: EventGroup['id'][]
    setMarkIds: React.Dispatch<React.SetStateAction<EventGroup['id'][]>>
    setNotificationSettingsExecute: React.Dispatch<
        React.SetStateAction<(input: SetNotificationSettingsInput) => void>
    >
    accessToken: string
    args: NotificationArgs
}

const NotificationFetchProvider = ({
    markIds,
    setMarkIds,
    setNotificationSettingsExecute,
    accessToken,
    args,
}: NotificationFetchProviderProps) => {
    const { setNotificationSettings, setNotifications } = useNotificationStore()

    const limit = 50

    const [settingsResult, reexecuteSettings] = useToastyQuery({
        query: notificationSettingsQuery,
    })

    const settings = settingsResult.data?.notificationSettings

    const [result, reexecute] = useToastyQuery({
        query: notificationsQuery,
        pause: settingsResult.fetching,
        variables: {
            ...args,
            limit, // set your desired limit value
        },
    })

    const [, executeMarkEventsRead] = useToastyMutation(markEventsReadMutation)
    const [, executeNotificationSettings] = useToastyMutation(notificationSettingsMutation)

    useEffect(() => {
        setNotificationSettingsExecute(() => async (variables) => {
            await executeNotificationSettings({ input: variables })
            reexecuteSettings()
        })
    }, [executeNotificationSettings, reexecuteSettings, setNotificationSettingsExecute])

    const notifications = result.data?.eventGroupsPage

    useEffect(() => {
        if (!notifications) return
        setNotifications(notifications)
    }, [notifications, setNotifications])

    const [updateResult] = useSubscription({
        query: latestNotificationTimestamp,
        variables: {
            token: accessToken,
        },
        pause: Capacitor.isNativePlatform(),
    })

    const updatedTimestamp = updateResult.data?.latestNotificationTimestamp

    // this sends the notifications to an higher level context
    // this is done because unread count is rendered outside this context
    useEffect(() => {
        if (!updatedTimestamp) return
        reexecute()
    }, [reexecute, updatedTimestamp])

    // this sends the notification settings to an higher level context
    // this is done because unread count is rendered outside this context
    useEffect(() => {
        setNotificationSettings(settings)
    }, [setNotificationSettings, settings])

    const markAndRefetch = useCallback(
        async (markIds) => {
            setMarkIds([])
            await executeMarkEventsRead({ input: { groupIds: markIds } })
            reexecute()
        },
        [executeMarkEventsRead, reexecute, setMarkIds]
    )

    useEffect(() => {
        if (!markIds.length) return
        markAndRefetch(markIds)
    }, [markAndRefetch, markIds])

    return <NotificationFetchContext.Provider value={{}} />
}

export const NotificationProvider = ({ children }) => {
    const [handleNotificationSettingsExecute, setNotificationSettingsExecute] = useState<
        (input: SetNotificationSettingsInput) => void
    >(() => () => null)
    const { notificationSettings } = useNotificationStore()

    const [markIds, setMarkIds] = useState<EventGroup['id'][]>([])
    const [args, setArgs] = useState<NotificationArgs>({
        read: false,
        grouped: notificationSettings?.notificationFormat !== 'COMPACT',
        cursor: undefined,
    })

    const { accessToken } = useFreshAccessToken()

    const client = useMemo(() => {
        if (!accessToken) return null
        return notifsClient({ accessToken })
    }, [accessToken])

    const saveNotificationSettings = useCallback(
        async (input) => {
            await handleNotificationSettingsExecute(input)
            return true
        },
        [handleNotificationSettingsExecute]
    )

    const handleArgs = useCallback(
        (newArgs) => {
            if (
                newArgs.cursor === args.cursor &&
                newArgs.read === args.read &&
                newArgs.grouped === args.grouped
            )
                return
            setArgs(newArgs)
        },
        [args.cursor, args.grouped, args.read]
    )

    return (
        <NotificationContext.Provider
            value={{
                saveNotificationSettings,
                markNotifications: setMarkIds,
                setArgs: handleArgs,
                args,
            }}
        >
            {children}
            {!!accessToken && !!client && (
                <UrqlProvider value={client}>
                    <NotificationFetchProvider
                        setNotificationSettingsExecute={setNotificationSettingsExecute}
                        markIds={markIds}
                        setMarkIds={setMarkIds}
                        accessToken={accessToken}
                        args={args}
                    />
                </UrqlProvider>
            )}
        </NotificationContext.Provider>
    )
}

export const useNotifications = (args: NotificationArgs = {}) => {
    const { notifications } = useNotificationStore()
    const { setArgs } = useContext(NotificationContext)

    useEffect(() => {
        setArgs(args)
    }, [args, setArgs])

    return notifications
}
