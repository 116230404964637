import { Badge } from '@capawesome/capacitor-badge'
import { Capacitor } from '@capacitor/core'

const safetyCheck = async () => {
    if (!Capacitor.isNativePlatform()) {
        return false
    }

    const isSupported = await Badge.isSupported()
    if (!isSupported) {
        console.log('Badge is not supported on this platform.')
        return
    }

    const hasPermission = await Badge.checkPermissions()
    if (!hasPermission) {
        console.log('Badge permission is not granted.')
        const permission = await Badge.requestPermissions()
        if (permission.display !== 'granted') {
            console.log('Badge permission is not granted.')
            return false
        }
    }

    console.info('Badge is supported and permission is granted.')
    return true
}

export const setBadgeCount = async (count) => {
    if (await safetyCheck()) {
        await Badge.clear()
        for (let i = 0; i < count; i++) {
            await Badge.increase()
        }
    }
}

export const clearBadgeCount = async () => {
    if (await safetyCheck()) {
        await Badge.clear()
    }
}

export const increaseBadgeCount = async () => {
    if (await safetyCheck()) {
        await Badge.increase()
    }
}

export const decreaseBadgeCount = async () => {
    if (await safetyCheck()) {
        await Badge.decrease()
    }
}

export const getBadgeCount = async () => {
    if (await safetyCheck()) {
        return await Badge.get()
    }
}
