import { gql } from '@urql/core'

export const ImageFragment = gql`
    fragment Image on PostImageNode {
        id
        image
        download
        thumbnail
        title
        attachmentToken
        attachmentDict {
            mimetype
            reference
            signature
            signatureSalt
        }
    }
`

export const VideoFragment = gql`
    fragment Video on VideoNode {
        id
        ref
        thumbnail
        url
        pollUrl
        status
        name
        deleteToken
        post
        uploader {
            ... on RoleNode {
                fullName
                relation
                photo
                type
            }
            ... on UnknownRole {
                fullName
                relation
                photo
            }
        }
        attachmentDict {
            mimetype
            reference
            signature
            signatureSalt
        }
    }
`

export const FileFragment = gql`
    fragment File on PostFileNode {
        id
        name
        file
        download
        attachmentToken
        attachmentDict {
            mimetype
            reference
            signature
            signatureSalt
        }
    }
`
