import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { setUser } from '@sentry/react'
import Cookie from 'js-cookie'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ClientOptions, Provider as UrqlProvider, createClient } from 'urql'
import { v4 as uuidv4 } from 'uuid'

import { getDeviceIdAndPermission } from '../app/push-notification'
import { LoadingFullPage } from '../components/loading/page'
import { authorizedClientConfig } from '../graphql/clients'
import { activeUser } from '../graphql/queries/me'
import { updateUserDevice } from '../graphql/queries/user'
import { RoleNode, UserNode } from '../graphql/types'
import { parseToken } from '../helpers/id'
import { QueryResult, ReloadQuery, useToastyMutation, useToastyQuery } from '../hooks/data'
import LocaleContext from './locale'
import { useQuery } from './route'

export interface UserToken {
    sub: RoleNode['id']
    email: RoleNode['email']
    iat: number
    exp: number
}

const UserContext = React.createContext<{
    user?: UserNode
    reload: ReloadQuery
    registerDevice: () => void
}>({ user: undefined, reload: async () => undefined, registerDevice: () => undefined })

export default UserContext

export const useUser = (props?: { pause?: boolean }): [UserNode, QueryResult, ReloadQuery] => {
    const { setLocale } = useContext(LocaleContext)

    const [result, reload] = useToastyQuery({ query: activeUser, pause: props?.pause })

    const user: UserNode = useMemo(
        () => !result.fetching && result.data?.activeUser,
        [result.data, result.fetching]
    )

    useEffect(() => {
        if (!user?.locale) return
        setLocale(user.locale)
    }, [setLocale, user?.locale])

    useEffect(() => {
        if (!user) return
        setUser({ id: user.id, username: `${user.firstName} ${user.lastName}` })
    }, [user])

    return useMemo(() => [user, result, reload], [user, result, reload])
}

export const useUserContext = () => useContext(UserContext)

export const UserProvider = ({ children }) => {
    const role = Cookie.get('X-Role')

    const { pathname, search } = useLocation()
    const fullPath = encodeURIComponent(`${pathname}${search}`)
    const navigate = useNavigate()
    const query = useQuery()

    const [user, userResult, reload] = useUser()
    const [isRegistered, setIsRegistered] = useState(false)

    const authUrl = process.env.REACT_APP_LOGIN as string
    const isAtAuth = pathname.startsWith(authUrl)

    // need a user go to login
    useEffect(() => {
        if (userResult.fetching) return
        if (isAtAuth) return

        if (query?.userToken) {
            const token = parseToken<UserToken>(query.userToken)
            if (!user?.email || (user?.email && token.email !== user.email)) {
                console.log(fullPath)
                return navigate(`${authUrl}?next=${fullPath}&userToken=${query.userToken}`)
            }
        }

        if (user) return
        navigate(pathname !== '/' ? `${authUrl}?next=${fullPath}` : authUrl)
    }, [
        authUrl,
        isAtAuth,
        navigate,
        pathname,
        query.userToken,
        fullPath,
        user,
        userResult.fetching,
    ])

    // has a user go to root
    useEffect(() => {
        if (userResult.fetching) return
        if (!user) return
        if (!isAtAuth) return
        navigate('/')
    }, [isAtAuth, navigate, pathname, user, userResult.fetching])

    // Fetch deviceId when user is logged in
    const [, executeUpdateUserDevice] = useToastyMutation(updateUserDevice)
    const registerDevice = useCallback(() => {
        getDeviceIdAndPermission().then(([deviceId, isActive]) => {
            if (deviceId) {
                executeUpdateUserDevice({ input: { deviceId, isActive } })
                setIsRegistered(true)
            }
        })
    }, [executeUpdateUserDevice])

    useEffect(() => {
        if (userResult.fetching) return
        if (!user) return
        if (!Capacitor.isNativePlatform()) return
        if (isRegistered) return
        // Assuming a function getDeviceId retrieves the device ID
        registerDevice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, userResult.fetching])

    const refreshKey = useRef<string>(uuidv4())
    const refreshTime = useRef<number>(Date.now())

    const handleRefresh = useCallback(() => {
        const refreshAge = (Date.now() - refreshTime.current) / 1000
        if (Capacitor.isNativePlatform() && refreshAge < 60 * 1) return // 1 minutes before refreshing (mobile)
        if (!Capacitor.isNativePlatform() && refreshAge < 0.5 * 60) return // 5 minutes before refreshing (web)
        refreshKey.current = uuidv4()
        refreshTime.current = Date.now()
    }, [])

    // refresh data when app is active
    useEffect(() => {
        App.addListener('appStateChange', (state) => {
            if (state.isActive) handleRefresh()
        })
    }, [handleRefresh])

    // Create new graphql client (with its own cache per role)
    const forceRefreshKey = refreshKey.current
    const roleClient = useMemo(() => {
        return createClient(authorizedClientConfig as ClientOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role, forceRefreshKey])

    return (
        <UserContext.Provider value={{ user, reload, registerDevice }} key={role}>
            {!userResult.fetching && isAtAuth && children}
            {!userResult.fetching && !!user && (
                <UrqlProvider value={roleClient}>{children}</UrqlProvider>
            )}
            {userResult.fetching && <LoadingFullPage />}
        </UserContext.Provider>
    )
}
