import { useCallback, useEffect } from 'react'
import { accessTokenQuery } from '../graphql/queries-notifs/notifications'
import { parseToken } from '../helpers/id'
import { useToastyQuery } from './data'

interface ParsedToken {
    roleId: number
    exp: number // expiration time
    iat: number // issued at
}

interface TokenResult {
    accessToken?: string
    parsedToken?: ParsedToken
}

// check accesstoken time to live and reload it if needed
const useFreshAccessToken = (): TokenResult => {
    // fetch accessToken
    const [accessTokenResult, reloadToken] = useToastyQuery({
        query: accessTokenQuery,
    })

    const accessToken: string | undefined = accessTokenResult.data?.accessToken
    const parsedToken = accessToken ? parseToken<ParsedToken>(accessToken) : undefined

    const checkToken = useCallback(() => {
        if (!parsedToken) return
        const ttl = Math.floor(parsedToken.exp - Date.now() / 1000)
        // console.log('checking token ttl', ttl)
        if (ttl <= 30) reloadToken() // get new token if ttl is less than 30 seconds
    }, [parsedToken, reloadToken])

    useEffect(() => {
        const intervalId = setInterval(checkToken, 10 * 1000)
        return () => clearInterval(intervalId)
    }, [checkToken])

    // retest the token on focus to prevent token expiration
    useEffect(() => {
        const onFocus = () => checkToken()
        window.addEventListener('focus', onFocus)
        return () => window.removeEventListener('focus', onFocus)
    }, [checkToken])

    return { accessToken, parsedToken }
}

export { useFreshAccessToken }
