import React, { useCallback, useEffect, useState } from 'react'
import { Language, Locale, extendedLanguages, localeToLanguage } from '../../i18n'
import { LoadingFullPage } from '../components/loading/page'
import { Locale as GraphqlLocale } from '../graphql/types'
import { polyglot } from '../helpers/i18n'

interface LocaleContextInterface {
    locale: Locale
    language: Language
    setLocale: (locale: string) => void
}

export const LocaleContext = React.createContext<LocaleContextInterface>(
    {} as LocaleContextInterface
)

/* Normalize a locale supplied in either "nl-nl" or "nl_nl" format
 * to normalized nl_NL format. */
export const normalizeLocale = (locale: string): Locale => {
    const splitRe = new RegExp('[_-]')
    const [lang, region] = locale.split(splitRe, 2)
    return (lang.toLowerCase() + '-' + (region || lang).toUpperCase()) as Locale
}

/* Normalize a locale for graphql to normalized NL_NL format. */
export const graphqlLocale = (locale: Locale): GraphqlLocale => {
    const splitRe = new RegExp('[_-]')
    const [lang, region] = locale.split(splitRe, 2)
    return (lang.toUpperCase() + '_' + region.toUpperCase()) as GraphqlLocale
}

export const browserLocale = (): Locale => {
    const langauge = document.documentElement.getAttribute('lang') || window.navigator.language

    if (langauge === 'nl') return 'nl-NL'
    if (langauge === 'fr') return 'fr-BE'
    if (langauge === 'en') return 'en-GB'
    if (langauge === 'da') return 'da-DK'
    if (langauge === 'de') return 'de-DE'
    if (langauge === 'nb') return 'nb-NO'

    const normalized = normalizeLocale(langauge)

    if (normalized === 'nl-NL') return 'nl-NL'
    if (normalized === 'fr-BE') return 'fr-BE'
    if (normalized === 'en-GB') return 'en-GB'
    if (normalized === 'da-DK') return 'da-DK'
    if (normalized === 'de-DE') return 'de-DE'
    if (normalized === 'nb-NO') return 'nb-NO'

    // default to english
    return 'en-GB'
}

const initialLocale = browserLocale()

export const LocaleProvider = ({ children }) => {
    const [locale, setLocale] = useState<Locale>(normalizeLocale(initialLocale))
    const [loading, setLoading] = useState(true)

    const getLocale = useCallback(async (locale: Locale) => {
        setLoading(true)

        // list of locales to load
        const localesToLoad: Locale[] = [...(extendedLanguages[locale] || ['en-GB']), locale]
        const promisses = localesToLoad.map((locale) => import(`../../i18n/${locale}.json`))
        const translations = await Promise.all(promisses)

        // load languages into polyglot
        polyglot.clear()
        translations.map((phrases) => polyglot.extend(phrases))
        polyglot.locale(locale)

        setLoading(false)
    }, [])

    useEffect(() => {
        getLocale(locale)
    }, [getLocale, locale])

    const handleSetLocale = useCallback((locale: string) => {
        document.documentElement.setAttribute('lang', normalizeLocale(locale))
        setLocale(normalizeLocale(locale))
    }, [])

    const context: LocaleContextInterface = {
        locale,
        language: localeToLanguage(locale),
        setLocale: handleSetLocale,
    }

    return (
        <LocaleContext.Provider value={context}>
            {loading ? <LoadingFullPage /> : null}
            {children}
        </LocaleContext.Provider>
    )
}

export default LocaleContext
