import React from 'react'

import { NavPublic } from '../../views/navigation/navbar'
import LoadingAnimation from './animation'

import '../../../scss/react/loading.scss'

interface Props {
    children?: React.ReactNode
    isLoading?: boolean
}

export const SplashPage = ({ children }: Props) => (
    <div className="splash-page">
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <rect width="1024" height="1024" fill="#e88d8d"></rect>
            <path
                fill="white"
                d="M 308.705 407.222 C 308.705 263.057 393.07 197.669 547.459 197.669 L 715.294 197.669 L 715.294 321.724 L 547.459 321.724 C 490.909 321.724 463.09 354.41 463.09 407.222 L 463.09 472.61 L 701.825 472.61 L 701.825 597.504 L 463.09 597.504 L 463.09 826.33 L 308.705 826.33 L 308.705 407.222 Z"
            ></path>
        </svg>
        <div>{children}</div>
    </div>
)

const LoadingPage = ({ children, isLoading = true }: Props) => (
    <div className="loading-page">
        {isLoading && <LoadingAnimation />}
        <div>{children}</div>
    </div>
)

export const LoadingFullPage = (props: Props) => (
    <div>
        <nav className="topbar">
            <NavPublic />
        </nav>
        <main>
            <LoadingPage {...props} />
        </main>
    </div>
)

export default LoadingPage
