import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { Capacitor } from '@capacitor/core'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { unflatten } from '../helpers/toast'
import { NotificationProps } from '../projects/notifications'
import { notificationLinkBuilder } from '../projects/notifications/links'
import { decreaseBadgeCount, increaseBadgeCount } from './badge'

const checkPermissions = async () => {
    const result = await FirebaseMessaging.checkPermissions()
    return result.receive
}

const requestPermissions = async () => {
    const result = await FirebaseMessaging.requestPermissions()
    return result.receive
}

const getToken = async () => {
    const result = await FirebaseMessaging.getToken()
    return result.token
}

export const deleteToken = async () => {
    await FirebaseMessaging.deleteToken()
}

export const getDeliveredNotifications = async () => {
    const result = await FirebaseMessaging.getDeliveredNotifications()
    console.log('Delivered notifications:', result.notifications)
    return result.notifications
}

export const removeAllDeliveredNotifications = async () => {
    await FirebaseMessaging.removeAllDeliveredNotifications()
}

export const subscribeToTopic = async () => {
    await FirebaseMessaging.subscribeToTopic({ topic: 'news' })
}

export const unsubscribeFromTopic = async () => {
    await FirebaseMessaging.unsubscribeFromTopic({ topic: 'news' })
}

export const addTokenReceivedListener = async () => {
    await FirebaseMessaging.addListener('tokenReceived', (event) => {
        console.log('tokenReceived', { event })
    })
}

interface NotificationData {
    [key: string]: string
}

export const addNotificationReceivedListener = async () => {
    await FirebaseMessaging.addListener('notificationReceived', (event) => {
        console.log('notificationReceived', { event })
        increaseBadgeCount()
    })
}

export const addNotificationActionPerformedListener = async (navigate) => {
    await FirebaseMessaging.addListener('notificationActionPerformed', (event) => {
        console.log('notificationActionPerformed', { event })
        if (!event.notification) return

        // Unflatten the data from the notification
        const data = unflatten(event.notification.data as NotificationData)

        // Try to build a link
        notificationLinkBuilder(data as NotificationProps).then((path) => {
            if (!path) return console.warn('No path found for notification:', event.notification)
            console.log('Navigate to:', path)
            navigate(path)
            decreaseBadgeCount()
        })
    })
}

export const removeAllListeners = async () => {
    await FirebaseMessaging.removeAllListeners()
}

export const addListeners = async (navigate) => {
    let permStatus = await checkPermissions()
    if (permStatus === 'granted') {
        // Remove all listeners
        removeAllListeners()

        // Add listeners
        addNotificationReceivedListener()
        addTokenReceivedListener()
        addNotificationActionPerformedListener(navigate)

        // Get the delivered notifications
        getDeliveredNotifications()
    }
}

export const getDeviceIdAndPermission = async (retries = 0): Promise<[string | null, boolean]> => {
    if (!Capacitor.isNativePlatform()) return [null, false]
    if (!Capacitor.isPluginAvailable('FirebaseMessaging')) return [null, false]
    if (!FirebaseMessaging.isSupported()) return [null, false]

    try {
        let permStatus = await checkPermissions()

        if (permStatus === 'prompt') {
            permStatus = await requestPermissions()
        }

        if (permStatus !== 'granted') {
            console.warn(`Notifications are not enabled: ${permStatus}`)
        }

        const token = await getToken()
        if (token) {
            return [token, permStatus === 'granted']
        }
    } catch (error) {
        toast.error(`Error getting device ID and permission: ${error}`)
        if (retries > 10) return [null, false]

        // Retry with timeouts since loading APNS token might take some time
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(getDeviceIdAndPermission(retries + 1))
            }, retries * 1000)
        })
    }

    return [null, false]
}

const AppPushNotification = () => {
    const navigate = useNavigate()

    // register for push notifications
    useEffect(() => {
        if (!Capacitor.isNativePlatform()) return
        console.log('Add listeners for push notifications')
        addListeners(navigate)
    }, [navigate])

    return null
}

export default AppPushNotification
