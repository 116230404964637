import * as Sentry from '@sentry/react'
import { fetchExchange, mapExchange, subscriptionExchange } from '@urql/core'
import { devtoolsExchange } from '@urql/devtools'
import { authExchange } from '@urql/exchange-auth'
import { cacheExchange } from '@urql/exchange-graphcache'
import { retryExchange } from '@urql/exchange-retry'
import { createClient as createWSClient } from 'graphql-ws'
import Cookie from 'js-cookie'
import { createClient } from 'urql'

import { refreshTokens } from '../helpers/oauth'
import Notification from '../helpers/toast'
import schema from './introspection.json'

const invalidateAll = (result, args, cache, info) => {
    cache.inspectFields('Query').forEach((query) => {
        // what cache we skip invalidation for
        if (['accessToken', 'allRoles', 'activeRole'].includes(query.fieldName)) return
        cache.invalidate('Query', query.fieldName, query.arguments)
    })
}

// list all the mutation
const listMutations = () => {
    const mutationType = schema.__schema.types.find(
        ({ name }) => name === schema.__schema.mutationType.name
    )
    return mutationType?.fields.map(({ name }) => name) || []
}

const mutationNames = listMutations()

// list of mutations that trigger cache invalidation
// for now this is all mutations but we can be more specific
// what mutation trigger which cache invalidation
const updates = {
    Mutation: mutationNames.reduce((list, mutationName) => {
        list[mutationName] = invalidateAll
        return list
    }, {}),
}

export const authorizedClientConfig = {
    url: process.env.REACT_APP_GRAPHQL,
    exchanges: [
        devtoolsExchange,
        cacheExchange({
            schema,
            updates,
        }),
        retryExchange({
            initialDelayMs: 0,
            maxDelayMs: 1000,
            maxNumberAttempts: 10,
            randomDelay: false,
            retryIf: (error) => {
                if (error.response?.status < 500 && error.response?.status !== 408) return false // Do not retry wrong requests (like 401)
                if (!!error.networkError) return true
                if (error.response?.status >= 500) return true
                return false
            },
        }),
        mapExchange({
            onError: (error, operation) => {
                Sentry.captureException(error)

                if (error.networkError) {
                    return
                }

                if (error.message === '[GraphQL] No role found in context') {
                    // Do not show error message for this, it's handled by the notification on the account settings page
                    return
                }

                Notification.error({
                    title: `Graphql Error - ${operation.query.definitions?.[0].name?.value || ''}`,
                    message: error?.message,
                    code: JSON.stringify(operation.variables, null, 2),
                })
            },
        }),
        authExchange(async (utils) => {
            return {
                addAuthToOperation(operation) {
                    const access_token = Cookie.get('access_token')
                    if (!access_token) return operation
                    const xRole = Cookie.get('X-Role')
                    if (xRole) {
                        return utils.appendHeaders(operation, {
                            Authorization: `Bearer ${access_token}`,
                            'X-Role': xRole,
                        })
                    }
                    return utils.appendHeaders(operation, {
                        Authorization: `Bearer ${access_token}`,
                    })
                },
                didAuthError: (error) => {
                    return error?.response?.status === 401
                },
                async refreshAuth() {
                    const refresh_token = Cookie.get('refresh_token')
                    if (refresh_token) await refreshTokens({ refresh_token })
                },
            }
        }),
        fetchExchange,
    ],
}

const timedOutFetch = (url, options) => {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 2500) // Set timeout to 5 seconds
    const fetchOptions = {
        ...options,
        signal: controller.signal, // Attach the abort signal to the request
    }
    return fetch(url, fetchOptions).finally(() => clearTimeout(timeoutId)) // Clear the timeout if request completes
}

export const authorizedClient = createClient({
    ...authorizedClientConfig,
    fetch: timedOutFetch,
})

export const publicClient = createClient({
    url: process.env.REACT_APP_GRAPHQL_PUBLIC,
    exchanges: [
        mapExchange({
            onError: (error, operation) => {
                console.error(error, operation)
                // @TODO sentry for production
                // @TODO message for development
                if (process.env.REACT_APP_GRAPHQL_ERRORS !== 'true') return
                Notification.error({
                    title: `Graphql Error - ${operation.query.definitions?.[0].name?.value || ''}`,
                    message: error?.message,
                    code: JSON.stringify(operation.variables, null, 2),
                })
            },
        }),
        fetchExchange,
    ],
})

// let timedOut
const wsClient = createWSClient({
    url: process.env.REACT_APP_GRAPHQL_NOTIFS_SOCKET,
    keepAlive: 30_000, // ping server every 30 seconds see: https://github.com/enisdenjo/graphql-ws/discussions/290
    shouldRetry: () => true,
    // on: {
    //     ping: (received) => {
    //         if (!received) {
    //             timedOut = setTimeout(wsClient.terminate, 25_000)
    //         }
    //     },
    //     pong: (received) => {
    //         if (received) clearTimeout(timedOut)
    //     },
    // },
})

export const notifsClient = ({ accessToken }) => {
    return createClient({
        url: process.env.REACT_APP_GRAPHQL_NOTIFS,
        exchanges: [
            mapExchange({
                onError: (error, operation) => {
                    console.error(error, operation)
                    // @TODO sentry for production
                    // @TODO message for development
                    if (process.env.REACT_APP_GRAPHQL_ERRORS !== 'true') return
                    Notification.error({
                        title: `Graphql Error - ${
                            operation.query.definitions?.[0].name?.value || ''
                        }`,
                        message: error?.message,
                        code: JSON.stringify(operation.variables, null, 2),
                    })
                },
            }),
            authExchange(async (utils) => {
                return {
                    addAuthToOperation(operation) {
                        if (!accessToken) return operation
                        return utils.appendHeaders(operation, {
                            Authorization: `Bearer ${accessToken}`,
                        })
                    },
                }
            }),
            fetchExchange,
            subscriptionExchange({
                forwardSubscription: (request) => ({
                    subscribe: (sink) => ({
                        unsubscribe: wsClient.subscribe(request, sink),
                    }),
                }),
            }),
        ],
    })
}
